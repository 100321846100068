<template>
    <div>
        <DataTable
            :value="periods"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped"
            :rowsPerPageOptions="rowsPerPageOptions"
            :rows="20"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :currentPageReportTemplate="`Показана с {first} по {last} всего ${totalRecords}`"
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :selection.sync="selectedPeriod"
            :filters="filters"
            stripedRows
            @row-dblclick='rowDblclickHandler'
        >
            <template #header>
                <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0">Периодичность</h5>
                    </div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-mt-2"></div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center">
                        <div class="p-inputgroup p-p-0 p-d-flex">
                            <span class="p-float-label">
                                <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                            </span>
                            <!-- <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span> -->
                        </div>
                        <div v-if='canEditPeriodicity' class="p-p-0">
                            <Button @click="$router.push('/control/periodicity/new')" class="p-button p-ml-3"> Добавить </Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Периодов не найдено.</template>
            <template #loading> Загрузка списка периодов. Пожалуйста, подождите. </template>
            <Column header="Наименование" field="attributes.name" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.attributes.name }}
                </template>
                <template #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['attributes.name']" class="p-column-filter" placeholder="Наименование" />
                </template>
            </Column>
            <Column header="" bodyClass="p-d-flex p-jc-end p-ai-center" bodyStyle="position: relative">
                <template v-if='accessibleItems(listMenuItems).length' #body="slotProps">
                    <button class="p-panel-header-icon p-link p-mr-2" @click.stop="showListMenu($event, slotProps.data.id, `listMenu${slotProps.data.id}`)">
                        <span class="pi pi-ellipsis-h"></span>
                    </button>
                    <Menu id="list_menu" class="redLastListElement" :ref="`listMenu${slotProps.data.id}`" :model="accessibleItems(listMenuItems)" :popup="true" />
                </template>
                <template #filter>
                    <Button icon="pi pi-filter " :class="filterClasses" class="p-button-rounded p-button-outlined filterPosition" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft>
                <div class="p-col-6"></div>
            </template>
        </DataTable>
        <Dialog :visible.sync="deletePeriodShow" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 400px" class="delDialog">
            <template #header>
                <h3 class="p-mb-0 p-pl-2 customTitle">Удалить период</h3>
            </template>
            <div class="p-dialog-content p-ai-center p-d-flex p-pl-2">
                <!-- <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i> -->
                <span>Подтвердите, пожалуйста, что вы удаляете период. Восстановить его не получится. </span>
            </div>

            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-danger" @click="removePeriod(currentPeriodId)">
                        <span class="p-button-label">Удалить</span>
                    </Button>
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="hideDeletePopUp">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { getAllFormsPeriods, deleteFormsPeriod } from '@/api/form/formsPeriods';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { USER_PERMISSIONS_MAP } from '@/constants/common';

const { formWrite } = USER_PERMISSIONS_MAP

export default {
    name: 'periodicityList',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    mixins: [requestToastHandler],
    emits: ['loadingChange'],
    data() {
        return {
            selectedPeriod: null,
            filters: {},
            periods: [],
            showFilters: false,
            currentPage: 1,
            totalRecords: null,
            currentPeriodId: null,
            deletePeriodShow: false,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editPeriod(this.currentPeriodId);
                    },
                    permissionAccess: [formWrite]
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        // this.showDeletePopUp(this.currentPeriodId);
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: () => this.removePeriod(this.currentPeriodId),
                            cancelAction: () => this.currentPeriodId = null
                        })
                    },
                    class: 'remove-row',
                    permissionAccess: [formWrite]
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? 'mainBackground' : '';
        },
        canEditPeriodicity() {
            return [formWrite].some(p => this.userPermissionsObject[p])
        }
    },
    methods: {
        rowDblclickHandler({ data: { id } }) {
            this.currentPeriodId = id
            this.editPeriod(id)
        },
        showDeletePopUp() {
            this.deletePeriodShow = true;
        },
        hideDeletePopUp() {
            this.deletePeriodShow = false;
            this.currentPeriodId = null;
        },
        showListMenu(event, periodId, index) {
            this.currentPeriodId = periodId;
            this.$refs[index].toggle(event);
        },
        async getPeriods() {
            try {
                this.$emit('loadingChange', true);
                const { allPeriods, meta } = await getAllFormsPeriods();
                this.periods = allPeriods;
                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        editPeriod(id) {
            if (!this.canEditPeriodicity) return
            this.$router.push(`/control/periodicity/${id}`);
        },
        removePeriod(id) {
            try {
                this.$emit('loadingChange', true);
                const result = deleteFormsPeriod(id); //если удаление успешно
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.periods = this.periods.filter((note) => note.id !== id);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Интервал успешно удален',
                //     life: '3200',
                // });
                this.hideDeletePopUp();
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange');
                return;
            }
        },
    },
    watch: {
        // selectedPeriod() {
        //     this.$router.push(`/control/periodicity/${this.selectedPeriod.id}`);
        // },
    },
    async mounted() {
        await this.getPeriods();
    },
};
</script>

<style lang='scss' scoped>
::v-deep .p-datatable-header {
    border-top: none;
}
::v-deep .p-datatable {
    .p-datatable-tbody > tr > td {
        border: none;
    }
}
::v-deep .redLastListElement {
    top: 10px !important;
    right: 10px !important;
    left: 76% !important;
    li:last-child {
        span {
            color: red !important;
        }
    }
}
.customTitle {
    font-size: 17px;
}
::v-deep .p-datatable-thead {
    position: relative;

    // .mainBackground {
    //     background-color: #388e3c;
    //     color: white;
    //     outline: none;
    //     border: none;
    // }
    .filterPosition {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 17px;
        top: 11px;
        background-color: #fff;
    }
    tr:last-child {
        th:last-child {
            text-align: right;
        }
    }
    .p-filter-column {
        padding: 0;
        background-color: #fff !important;
        input {
            min-width: 98%;
            max-width: 98%;
            margin: 5px 0;
        }
        .p-dropdown-clearable {
            max-width: 98%;
            margin: 5px 15%;
        }
    }
}
</style>
